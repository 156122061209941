import React from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";

import Blog from "../../assets/json/blog.json";

const articleData = {
    id: 5,
    title: "Tutti pazzi per il Nomadismo Digitale",
    desc:
        "Un Nomade Digitale è una persona che lavora da remoto, ma come mai ora sembra essere una vera e propria tendenza?",
    img: "/blog-image/nomade.jpg",
    page: "blog/nomadismo_digitale",
    data: "01 Ago 2022",
    author: "Staff",
};

const BlogDetails = () => (
    <Layout>
        <SEO
            title={articleData.title}
            meta={[
                {
                    name: `description`,
                    content:
                        "Un Nomade Digitale è una persona che lavora da remoto e che quindi può svolgere la sua attività dove desidera.",
                },
                {
                    property: `og:title`,
                    content: articleData.title,
                },
            ]}
        />

        <Navbar />

        <PageBanner pageTitle={articleData.title} />

        <div className="blog-details-area ptb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="blog-details-desc">
                            <div className="article-image">
                                <img
                                    src={
                                        require("../../assets/images" +
                                            articleData.img).default
                                    }
                                    alt="image"
                                />
                            </div>

                            <div className="article-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li>
                                            <Icon.Clock /> {articleData.data}
                                        </li>
                                        <li>
                                            <Icon.User />{" "}
                                            <Link to="#">
                                                {articleData.author}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <p>
                                    Un Nomade Digitale è una persona che lavora
                                    da remoto e che quindi può svolgere la sua
                                    attività dove desidera. Le uniche cose di
                                    cui ha bisogno sono una connessione internet
                                    ed un’apparecchiatura adeguata.
                                    <br />
                                    Ma come mai ora sembra essere una vera e
                                    propria tendenza?
                                </p>
                                <p>
                                    La pandemia ha “obbligato” il mondo a
                                    digitalizzarsi in fretta e superare il tabù
                                    del lavoro da remoto. Quanti di voi hanno
                                    conoscenti che a seguito del Covid-19
                                    adottano una strategia ibrida, o che non
                                    sono più tornati in ufficio e lavorano da
                                    casa al 100%? Può darsi che tu stia leggendo
                                    questo articolo proprio da casa tua!
                                    <br />
                                    Eppure, il termine “Nomade Digitale” non è
                                    così recente. Oggi sembra essere più usato e
                                    un numero crescente di persone sembrano
                                    ambire a diventarlo. Vediamo come mai.
                                </p>
                                <p>
                                    Immagina di poter vivere viaggiando. Se sei
                                    un appassionato viaggiatore con il costante
                                    desiderio di scoprire nuovi posti e visitare
                                    nuove città allora il nomadismo digitale può
                                    essere una buona alternativa alla tua
                                    scrivania. Se sei bravo nel tuo lavoro, hai
                                    un pc, le giuste tecnologie (soprattutto
                                    funzionali) e sei in grado di rispettare le
                                    scadenze richieste dai tuoi clienti o dal
                                    tuo datore di lavoro, allora puoi iniziare a
                                    mettere lo zaino in spalla.
                                </p>

                                <img
                                    src={
                                        "https://i0.wp.com/techfromthenet.it/wp-content/uploads/2018/05/images_articoli_0Libreria_Mobile_sostenibilita.jpg?fit=740%2C493&ssl=1"
                                    }
                                    style={{ marginBottom: 40, marginTop: 30 }}
                                    alt="Multiverso"
                                />
                                <br />

                                <p>
                                    Dato che le reti libere Wi-Fi sono
                                    disponibili ormai ovunque, è bene tenere a
                                    mente che ad ogni posto dove è possibile
                                    accedere ad internet corrisponde un luogo da
                                    proteggere da attacchi informatici. Per
                                    questo, è necessario assicurarsi sempre che
                                    le linee e i sistemi che andrai ad
                                    utilizzare siano protetti in modo adeguato.
                                    <br />I dati più recenti riportano un
                                    incremento sostanzioso della popolazione che
                                    si definisce Nomade Digitale. Alcune
                                    ricerche svolte sulla popolazione americana,
                                    indicate su Passport-photo.online,
                                    sottolineano la crescita, in particolare dal
                                    2019, dei lavoratori che decidono di
                                    esplorare il Mondo lavorando. I più
                                    entusiasti di questo nuovo modo di lavorare
                                    sembrano essere i Millenials e il settore
                                    professionale di appartenenza più comune è
                                    l’IT.
                                </p>
                                <p>
                                    Nonostante una grossa fetta di Nomadi sia
                                    rappresentata da freelance, sempre più
                                    lavoratori dipendenti decidono di portare
                                    avanti il loro ruolo da mete lontane.
                                    <br />I viaggi sono accessibili ad un numero
                                    crescente di cittadini. La possibilità di
                                    viaggiare e lavorare contemporaneamente,
                                    tuttavia, è una pratica ancora poco diffusa
                                    in Italia. Inoltre, avere il supporto delle
                                    persone che nel quotidiano collaborano con
                                    te è un aspetto psicologico molto
                                    importante. I Nomadi Digitali viaggiano
                                    spesso da soli o, per via della propria
                                    attività, passano molto tempo in solitudine.
                                    Per questa ragione una buona comunicazione e
                                    il giusto appoggio da parte delle persone a
                                    te care e vicine, anche a livello
                                    lavorativo, è un fattore che non deve essere
                                    trascurato!{" "}
                                </p>
                                <p>
                                    “Viaggiare apre la mente” e tante nuove
                                    porte. Può essere una vera occasione per il
                                    tuo business, per accrescerlo o crearlo.
                                    <br />
                                    Noi di Appius Software House abbracciamo
                                    questa frontiera del lavoro agile mettendo
                                    la soddisfazione di clienti e collaboratori
                                    al primo posto. Ci presentiamo come una
                                    Software House felice perché la felicità è
                                    quello che ci aspettiamo di generare e
                                    ricevere. Sappiamo che non è un luogo fisico
                                    e statico a generare un buon lavoro e un
                                    lavoratore serio, ma la qualità e la
                                    soddisfazione con cui quest’ultimo lo
                                    svolge. Ci facciamo promotori ogni giorno
                                    delle nuove tendenze per lo svolgimento
                                    della propria attività. Non ci interessa
                                    dove, ma come.
                                    <br />
                                    La nuova frontiera del lavoro da remoto è
                                    qui e noi siamo pronti a sostenerla!
                                </p>

                                <br />
                                <Link to="/contact" className="btn btn-primary">
                                    Contattaci
                                </Link>
                            </div>

                            <div
                                style={{ marginTop: 30 }}
                                className="startp-post-navigation"
                            >
                                {Blog.articles
                                    .filter((a) => a.id != articleData.id)
                                    .slice(0, 2)
                                    .map((article) => {
                                        return (
                                            <div className="prev-link-wrapper">
                                                <div className="info-prev-link-wrapper">
                                                    <Link
                                                        to={"/" + article.page}
                                                    >
                                                        <span className="image-prev">
                                                            <img
                                                                src={
                                                                    require("../../assets/images" +
                                                                        article.img)
                                                                        .default
                                                                }
                                                                alt="image"
                                                            />
                                                            <span className="post-nav-title">
                                                                VAI
                                                            </span>
                                                        </span>

                                                        <span className="prev-link-info-wrapper">
                                                            <span className="prev-title">
                                                                {article.title}
                                                            </span>
                                                            <span className="meta-wrapper">
                                                                <span className="date-post">
                                                                    {
                                                                        article.data
                                                                    }
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <BlogSidebar id={articleData.id} />
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </Layout>
);

export default BlogDetails;
